const FormatDate = (dateString) => {
    const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }
      
      // test code
      
      const dateStr = new Date(dateString);
      const date = dateStr.getDate();
      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][dateStr.getMonth()];
      
      return <span>{month} {date}<sup>{nth(date)}</sup> {dateStr.getFullYear()}</span>;
      
      
}

export default FormatDate;