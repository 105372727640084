import {useState, useEffect} from 'react';
import Container from './Container';

export default function ContactForm({}) {
    
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [formMessage, setFormMessage] = useState("");

    //   Form validation state
    const [errors, setErrors] = useState({});

    //   Setting button text on form submission
    const [buttonText, setButtonText] = useState("Send");

    // Setting success or failure messages states
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);

    const handleValidation = () => {
        let tempErrors = {};
        let isValid = true;
    
        if (firstName.length <= 0) {
          tempErrors["firstName"] = true;
          isValid = false;
        }
        if (email.length <= 0) {
          tempErrors["email"] = true;
          isValid = false;
        }
        if (formMessage.length <= 0) {
          tempErrors["message"] = true;
          isValid = false;
        }
    
        setErrors({ ...tempErrors });
        console.log("errors", errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let isValidForm = handleValidation();
    
        if (isValidForm) {
            setButtonText("Sending");
            const res = await fetch("/api/contact", {
                body: JSON.stringify({
                email: email,
                firstname: firstName,
                lastname: lastName,
                telephone : telephone,
                message: formMessage
                }),
                headers: {
                "Content-Type": "application/json",
                },
                method: "POST",
            });
    
            const { error } = await res.json();
            if (error) {
                console.log(error);
                setShowSuccessMessage(false);
                setShowFailureMessage(true);
                setButtonText("Send");
        
                // Reset form fields
                setFirstName("");
                setLastName("");
                setEmail("");
                setTelephone("");
                setFormMessage("");
                return;
            }

            setShowSuccessMessage(true);
            setShowFailureMessage(false);
            setButtonText("Send");
            setFirstName("");
            setLastName("");
            setEmail("");
            setTelephone("");
            setFormMessage("");
            return;
        };
      };

    useEffect(() => {
        const timeoutID = window.setTimeout(() => {
            setShowSuccessMessage(false);
        }, 5000);
    
        return () => window.clearTimeout(timeoutID );
    }, [showSuccessMessage])

    return (
        <div id={`contact-form`} className="my-12 px-6">
            <Container>
            <form className="grid grid-cols-6 gap-6 max-w-3xl mx-auto" onSubmit={handleSubmit}>
                    <div className="col-span-6 sm:col-span-3">
                        <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label htmlFor="firstName" className="block text-xs font-medium text-gray-900">
                                First name
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }} 
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                autoComplete="given-name"
                                placeholder={"Jane"}
                            />
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label htmlFor="lastName" className="block text-xs font-medium text-gray-900">
                                Last name
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }} 
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                autoComplete="given-name"
                                placeholder={"Smith"}
                            />
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label htmlFor="email" className="block text-xs font-medium text-gray-900">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }} 
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                autoComplete="email"
                                placeholder={"janesmith@gmail.com"}
                            />
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label htmlFor="telepone" className="block text-xs font-medium text-gray-900">
                                Telephone
                            </label>
                            <input
                                type="tel"
                                id="telephone"
                                name="telephone"
                                value={telephone}
                                onChange={(e) => {
                                    setTelephone(e.target.value);
                                }} 
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                autoComplete="phone"
                                placeholder={"0123456789"}
                            />
                        </div>
                    </div>
                    <div className="col-span-6">
                        <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label htmlFor="formMessage" className="block text-xs font-medium text-gray-900">
                                Message
                            </label>
                            <textarea
                                id="formMessage"
                                name="formMessage"
                                value={formMessage}
                                onChange={(e) => {
                                    setFormMessage(e.target.value);
                                }} 
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                placeholder={"Hello world..."}
                            />
                        </div>
                    </div>
                    <div className="col-span-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3 text-left order-last sm:order-first">
                                {showSuccessMessage && (
                                <p className="text-green-800 font-semibold text-sm my-2">
                                    Thankyou! <br/> Your Message has been delivered.
                                </p>
                                )}
                                {showFailureMessage && (
                                <p className="text-red-500">
                                    Oops! Something went wrong, please try again.
                                </p>
                                )}
                            </div>
                            <div className="col-span-6 sm:col-span-3 text-right">
                                <input type="submit" value={buttonText} className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-zinc-800 hover:bg-zinc-900 px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2 sm:w-auto cursor-pointer"  />
                            </div>
                        </div>
                    </div>
                    
                </form>
            </Container>
        </div>
    )
}