import capitalize from "../utils/capitalize";
import Image from 'next/image';
import FormatDate from "../utils/formatDate";

const BlogSection = ({articles}) => {
    return (
        <>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {articles.map((post) => {
                return (
                <article key={post.id} className="flex flex-col items-start justify-between">
                    <div className="relative w-full">
                        <a href={"/" + post.full_slug}>
                            <Image
                                src={post.content.feature_image.filename + "/m/672x0"}
                                alt={post.content.feature_image.alt}
                                width={672}
                                height={336}
                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                            />
                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                        </a>
                    </div>
                    <div className="max-w-xl">
                        <div className="mt-8 flex items-center gap-x-4 text-xs">
                            <time dateTime={post.first_published_at} className="text-gray-500">
                                {FormatDate(post.first_published_at)}
                            </time>
                            <span
                                className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                            >
                                {capitalize(post.content.category)}
                            </span>
                        </div>
                        <div className="group relative">
                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                <a href={"/" + post.full_slug}>
                                <span className="absolute inset-0" />
                                    {post.name}
                                </a>
                            </h3>
                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.content.description}</p>
                        </div>
                    </div>
                </article>
                )

                })}
            </div>
        </>
    )
}

export default BlogSection;