import Image from "next/image";
import Container from "./Container";

export default function Hero({data}) {
    let showHero = false;
    const { title, links, fullHeight } = data;

    return (
        <div id="hero" className={`bg-gray-800 relative ${fullHeight ? 'h-[calc(100vh-118px)] sm:h-[calc(100vh-48px)]' : 'py-12 sm:py-24'}`}>

            <div className="max-w-3xl mx-auto flex flex-col flex-wrap justify-center items-center h-full px-6">
                <h1 className="text-3xl sm:text-4xl text-white leading-snug mb-6">{title}</h1>
                <div className="flex justify-start w-full">
                {links.length > 0 && 
                    (
                        <ul className="flex">
                            {links.map((link, index) => {
                                if(link.link.url && link.label) {
                                    return (
                                        <li key={"herolink_"+index} className="text-white text-sm rounded-full bg-gray-600 py-2 px-6 mx-2">
                                            <a href={link.link.url}>
                                                {link.label}
                                            </a>
                                        </li>
                                    )
                                }
                                if(link.link.cached_url && link.label) {
                                    return (
                                        <li key={"herolink_"+index} className="text-white text-sm rounded-full bg-gray-600 py-2 px-6 mx-2">
                                            <a href={link.link.cached_url}>
                                                {link.label}
                                            </a>
                                        </li>
                                    )
                                }
                                return null;
                                
                            })}
                        </ul>
                    )
                }
                </div>
                
            </div>
        </div>
    )
}