import capitalize from "../utils/capitalize";
import RichText from "./RichText";
import Image from "next/image";
import Link from 'next/link';
import FormatDate from '../utils/formatDate';

  export default function LatestPosts({data, articles}) {
    return (
      <div id="latestposts" className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{data.title}</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              {data.description}
            </p>
            <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
                {!articles?.length && (
                    <p className="text-lg">There are no articles currently please check back soon.</p>
                )}
                {articles?.map((post) => {
                    return (
                    <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                      <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                        <Link href={"/" + post.full_slug} title={post.name}>
                          <Image
                                src={post.content.feature_image.filename + "/m/672x0"}
                                alt={post.content.feature_image.alt}
                                width={672}
                                height={336}
                                className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                          />
                          <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                        </Link>
                      </div>
                      <div>
                          <div className="flex items-center gap-x-4 text-xs">
                          <time dateTime={post.datetime} className="text-gray-500">
                              {FormatDate(post.first_published_at)}
                          </time>
                          <span
                              className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
                          >
                              {capitalize(post.content.category)}
                              
                          </span>
                          </div>
                          <div className="group relative max-w-xl">
                          <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                              <Link href={"/" + post.full_slug} title={post.name}>
                                  <span className="absolute inset-0" />
                                  {post.name}
                              </Link>
                          </h3>
                          <RichText content={post.content.description} className="mt-5 text-sm leading-6 text-gray-600" />
                          </div>
                          {/* <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                          <div className="relative flex items-center gap-x-4">
                              <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                              <div className="text-sm leading-6">
                              <p className="font-semibold text-gray-900">
                                  <a href={post.author.href}>
                                  <span className="absolute inset-0" />
                                  {post.author.name}
                                  </a>
                              </p>
                              <p className="text-gray-600">{post.author.role}</p>
                              </div>
                          </div>
                          </div> */}
                      </div>
                    </article>
                )})}
            </div>
          </div>
        </div>
      </div>
    )
  }