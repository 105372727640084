import { 
    render, 
    MARK_LINK,
    NODE_PARAGRAPH, 
    NODE_CODEBLOCK,
    NODE_HEADING } from 'storyblok-rich-text-react-renderer';
import Link from 'next/link';
import Highlight from 'react-highlight'

export default function RichText({ content, className, charLimit}) {
    return <div className={className}>
        {render(content, {
            markResolvers: {
                [MARK_LINK]: (children, props) => {
                    const { linktype, href, target } = props;
                    if (linktype === 'email') {
                        // Email links: add `mailto:` scheme and map to <a>
                        return <a href={`mailto:${href}`}>{children}</a>;
                    }
                    if (href.match(/^(https?:)?\/\//)) {
                        // External links: map to <a>
                        return <a href={href} target={target} className="text-red-600">{children}</a>;
                    }
                    // Internal links: map to <Link>
                    return <Link href={href} className="text-red-200">{children}</Link>; 
                }
            },
            nodeResolvers: {
                [NODE_PARAGRAPH]: (children, props) => {
                    return <p className="mb-3">{children}</p>;
                },
                [NODE_CODEBLOCK]: (children, { level }) => { 
                    return <Highlight className="my-6">{children}</Highlight>;
                },
                [NODE_HEADING]: (children, { level }) => { 
                    if(level == 2) {
                        return <h2 className="text-lg mb-2 font-semibold mt-8">{children}</h2>
                    }
                    if(level == 3) {
                        return <h3>{children}</h3>
                    }
                    if(level == 4) {
                        return <h4>{children}</h4>
                    }
                    if(level == 5) {
                        return <h5>{children}</h5>
                    }
                    if(level == 6) {
                        return <h6>{children}</h6>
                    }
                    
                }
            }
        })}
    </div>;
}
